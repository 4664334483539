import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import NotFoundImage from "../images/svg/not_found.svg"
import { useTranslation } from "react-i18next"

export default function NotFound() {
  const { t } = useTranslation()

  return (
    <Layout showButtons={false}>
      <div className="flex flex-col items-center justify-center pt-24 sm:flex-row">
        <NotFoundImage className="w-64 h-64" alt="" />
        <div className="mt-12 text-center sm:ml-12">
          <h1 className="text-2xl">{t("notFoundTitle")}</h1>
          <p className="text-sm">{t("notFoundSubtitle")}</p>
          <button
            onClick={()=>{navigate("/")}}
            className="btn-primary"
          >
            {t("buttonHome")}
          </button>
        </div>
      </div>
    </Layout>
  )
}
